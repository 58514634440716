import http from "../../BaseServiceHttp";

class GiveawaySamplingService {
  fetchSessionInProgress(productionUnitId) {
    return http.get(`/production-units/${productionUnitId}/sampling-session-in-progress`);
  }

  fetchSessionSamples(productionUnitId, sessionId) {
    return http.get(`/production-units/${productionUnitId}/sampling-sessions/${sessionId}/samples`);
  }

  createSamplingSession(productionUnitId, httpData) {
    return http.post(`/production-units/${productionUnitId}/sampling-sessions`, httpData);
  }

  cancelSamplingSession(productionUnitId, sessionId, comment) {
    const payload = {
      comment: comment,
    };
    return http.put(`/production-units/${productionUnitId}/sampling-sessions/${sessionId}/cancel`, payload);
  }

  completeSamplingSession(productionUnitId, sessionId, comment) {
    const payload = {
      comment: comment,
    };
    return http.put(`/production-units/${productionUnitId}/sampling-sessions/${sessionId}/complete`, payload);
  }
}

export default new GiveawaySamplingService();
