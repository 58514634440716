<template>
  <v-card
    :wxid="$options.name"
    :class="{ disabled: isTileDisabled, unfocused: isTileUnfocused }"
    class="tile product-average-giveaway unit-name"
    tag="section"
    sf="4"
  >
    <grouped-tile-selector-menu :tile-index="tileIndex" />
    <div class="tile-inner">
      <h3 :title="unitNamedTitle" class="primary-title">
        {{ unitNamedTitle }}
        <v-icon v-if="!isTileAuthorized()">mdi-lock</v-icon>
      </h3>
      <h2 class="primary-value">{{ primaryValue }}</h2>
      <p class="value-sub-text__unit">({{ $t("tiles.currentProductNote") }})</p>
      <section class="status-details">
        <dl>
          <dt>{{ $t("dashboard.targetInfo.product") }}</dt>
          <dd class="d-flex align-center">
            <v-icon class="mr-1">mdi-target</v-icon>
            {{ targetQuantityUnits }}
          </dd>
        </dl>
      </section>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import GroupedTileSelectorMenu from "@/components/dashboard/tiles/GroupedTileSelectorMenu";
import TileBase from "@/components/dashboard/tiles/TileBase";
import Tiles from "@/components/Tiles";
import Helpers, { dash } from "@/helpers";

export default {
  name: "TileProductAverageGiveaway",
  extends: TileBase,
  inheritAttrs: false,
  components: { GroupedTileSelectorMenu },
  computed: {
    ...mapGetters("dashboard", ["averageProductGiveaway"]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    ...mapGetters("tiles", ["selectedTileIndex"]),
    isTileUnfocused() {
      return this.selectedTileIndex !== null && this.tileIndex !== this.selectedTileIndex;
    },
    isTileDisabled() {
      return !this.isTileAuthorized();
    },
    unitName() {
      if (this.averageProductGiveaway.unit && this.isTileAuthorized()) {
        return this.averageProductGiveaway.unit;
      } else {
        return "";
      }
    },
    unitNamedTitle() {
      switch (this.averageProductGiveaway.unit) {
        case "kg":
          return this.$t("tiles.productAverageWeight");
        case "lb":
          return this.$t("tiles.productAverageWeight");
        case "g":
          return this.$t("tiles.productAverageWeight");
        case "oz":
          return this.$t("tiles.productAverageWeight");
        case "L":
          return this.$t("tiles.productAverageVolume");
        case "mL":
          return this.$t("tiles.productAverageVolume");
        case "m":
          return this.$t("tiles.productAverageLength");
        case "ft":
          return this.$t("tiles.productAverageLength");
        default:
          return this.$t("tiles.productAverageGiveaway");
      }
    },
    primaryValue() {
      let roundedValueOrDash = Helpers.getRoundedValueOrZeroOrDash(
        this.averageProductGiveaway?.value,
        this.isTileAuthorized(),
      );
      return roundedValueOrDash !== dash ? `${roundedValueOrDash} ${this.unitName}` : roundedValueOrDash;
    },
    targetQuantityUnits() {
      let roundedValueOrDash = Helpers.getRoundedValueOrDash(
        this.averageProductGiveaway?.target,
        this.isTileAuthorized(),
      );
      return roundedValueOrDash !== dash ? `${roundedValueOrDash} ${this.unitName}` : roundedValueOrDash;
    },
    targetStatusClass() {
      let statusClass;
      if (this.averageProductGiveaway.target && this.averageProductGiveaway.value) {
        if (this.averageProductGiveaway.target < 0) {
          statusClass = this.errorCssClass;
        } else {
          const value = this.averageProductGiveaway.value - this.averageProductGiveaway.target;
          if (value <= 0) {
            statusClass = this.successCssClass;
          } else if (value > 0) {
            statusClass = this.warningCssClass;
          } else {
            statusClass = this.errorCssClass;
          }
        }
      }
      return statusClass;
    },
  },
  methods: {
    isTileAuthorized() {
      return this.activePuHasRequiredFeature(Tiles.productGiveaway.requiredFeature);
    },
  },
};
</script>
