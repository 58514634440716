<template>
  <fragment>
    <v-card :style="inlineStyleByBreakpoint" class="wx-panel panel-downtime-justification">
      <v-btn
        @click="unselectDowntime"
        :title="$t('dashboard.panelHeader.hideJustificationPanel_hoverTitle')"
        absolute
        top
        right
        icon
        large
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-form ref="justificationForm" lazy-validation autocomplete="off" class="d-flex flex-column">
        <header class="panel-header">
          <div class="d-flex flex-column flex-md-row">
            <h3>{{ $t("justification.panel.title") }}</h3>
            <p v-if="selectedDowntime" class="mb-0 ml-md-3">
              <span class="downtime-specs">
                {{ `${downtimeStart} - ${downtimeEnd} ` }}
              </span>
              <span class="font-weight-light">
                {{ `(${selectedDowntimeDuration})` }}
              </span>
            </p>
          </div>

          <wx-btn-standard v-if="isSplitByWorkShiftEnabled" class="mr-2" @click="clearAndSplitByWorkShift()">
            {{ $t("justification.panel.splitByShiftButton") }}
          </wx-btn-standard>

          <v-tooltip v-if="!newJustificationRowAccepted" top max-width="300">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <wx-btn-standard disabled class="mr-2">
                  <v-icon left>mdi-plus</v-icon>
                  {{ $t("justification.panel.addCauseButton") }}
                </wx-btn-standard>
              </div>
            </template>
            <span>{{ $t("justification.panel.newJustificationDisabledTooltip") }}</span>
          </v-tooltip>
          <wx-btn-standard
            v-if="newJustificationRowAccepted"
            @click="addJustificationRow"
            :title="$t('justification.panel.addCauseButtonHoverTitle')"
            :disabled="isJustificationButtonDisabled"
            class="mr-2"
          >
            <v-icon left>mdi-plus</v-icon>
            {{ $t("justification.panel.addCauseButton") }}
          </wx-btn-standard>

          <wx-btn-standard
            id="pendo-submit-justification-btn"
            color="primary"
            @click="onSubmitBtnClick"
            :title="$t('common.formSubmitHoverTitle')"
            :disabled="disableSubmitJustification"
          >
            {{ $t("common.submit") }}
          </wx-btn-standard>

          <v-btn-toggle class="arrows" borderless dense>
            <wx-btn-icon
              @click="selectPreviousDowntime"
              :title="$t('justification.panel.navArrows.previous')"
              :disabled="downtimeSelectedIsFirst"
              color="secondary"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </wx-btn-icon>

            <wx-btn-icon
              @click="selectNextDowntime"
              :title="$t('justification.panel.navArrows.next')"
              :disabled="downtimeSelectedIsLast"
              color="secondary"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </wx-btn-icon>
          </v-btn-toggle>
        </header>
        <section class="panel-body flex-column scrollbar-y-container">
          <fieldset class="justification-row" v-for="(j, index) in justificationRows" :key="`reason-${index}`">
            <!-- - - - - - - - - -     Reason Selector     - - - - - - - - - - - - -->
            <justification-reason-entry-dialog
              v-model="j.reasonId"
              @input="(reasonId) => onSelectedReason(j, reasonId, index)"
              :justification-row="j"
              :downtime="selectedDowntime"
              :reasons="availableReasonsFlattenTree"
              :activator-label="$t('justification.panel.downtimeReason')"
              :activator-hover-title="$t('justification.panel.selectADowntimeReason')"
              :call-to-action="$t('justification.panel.selectADowntimeReason')"
              :dialog-card-title="$t('justification.panel.selectADowntimeReason')"
              :error-messages="j.reasonSelectorErrorMessages"
              activator-css-class="select-reason"
              activator-not-dense
            >
              <template v-slot:reason-entry-item="{ item }">
                <div class="select-reason--items">
                  <v-icon class="state-icon mr-2">
                    {{ getPlannedUnplannedIcon(item) }}
                  </v-icon>
                  <span class="name">
                    {{ item.name }}
                  </span>
                  <span v-if="item.targetDurationHHMM.length > 0" class="inline-target-value pl-3">
                    <v-icon small>mdi-target</v-icon>
                    <time>{{ item.targetDurationHHMM }}</time>
                  </span>
                </div>
              </template>
            </justification-reason-entry-dialog>

            <!-- - - - - - - - - - - -    Duration       - - - - - - - - - - - - - -->
            <wx-timer-duration-input
              v-if="j.isDurationMandatory"
              v-model="j.duration"
              :title="reasonDurationInputLabel"
              :support-seconds="true"
              :rules="[() => validateDuration(j)]"
              :error-messages="j.durationErrorMessages"
              :disabled="isDurationDisabled(j)"
              class="input-justification"
              @newDuration="(newDuration) => onNewDuration(j, newDuration)"
            />
            <!-- - - - - - - - - - - -     Comment       - - - - - - - - - - - - - -->
            <wx-text-field
              v-model="j.comment"
              :label="$t('justification.panel.commentLabel')"
              :rules="[() => validateComment(index)]"
              class="input-comment"
              clearable
            />
            <!-- - - - - - - - - -     Remove button       - - - - - - - - - - - - -->
            <wx-btn-standard
              :title="$t('shiftWizard.removeTimeBlockHoverTitle')"
              :disabled="!isDeletionAllowed(index)"
              @click="removeJustification(index)"
              class="btn-delete ml-auto mt-sm-2"
              text
            >
              <v-icon color="error">mdi-minus-circle</v-icon>
            </wx-btn-standard>
          </fieldset>
        </section>
      </v-form>
    </v-card>
    <submit-oop-justification-dialog
      :show="showConfirmJustificationDialog"
      @confirm="submit"
      @cancel="onCancelSubmitBtnClick"
    />
  </fragment>
</template>

<script>
import { DateTime, Duration } from "luxon";
import { mapActions, mapGetters } from "vuex";
import JustificationReasonEntryDialog from "./dialogs/JustificationReasonEntryDialog";
import WxBtnIcon from "@/components/ui/WxBtnIcon";
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import WxTimerDurationInput from "@/components/ui/WxTimerDurationInput";
import WxTextField from "@/components/ui/WxTextField";
import DurationUtils, { TIME_FORMAT_HH_MM_SS } from "@/components/DurationUtils";
import * as TimeUtils from "@/store/TimeUtils";
import ErrorHandling from "@/components/ErrorHandling";
import DowntimeJustificationService from "@/components/dashboard/DowntimeJustificationService";
import SubmitOopJustificationDialog from "@/components/dashboard/dialogs/SubmitOopJustificationDialog.vue";

const NO_PRODUCTION_REASON_ID = "00000000-0000-0000-0000-000000000000";

function formatDurationForInput(startTimeMillis, endTimeMillis) {
  if (endTimeMillis) {
    let duration = Duration.fromMillis(endTimeMillis - startTimeMillis);
    return duration.toFormat(TIME_FORMAT_HH_MM_SS);
  } else {
    return "";
  }
}

function isDurationLocked(selectedReasonId, availableDowntimeReasons) {
  const reason = availableDowntimeReasons.find((r) => r.id === selectedReasonId);
  if (reason) {
    return reason.target_duration_in_seconds !== null;
  }
  return false;
}

export default {
  name: "Justification",
  components: {
    SubmitOopJustificationDialog,
    JustificationReasonEntryDialog,
    WxBtnStandard,
    WxBtnIcon,
    WxTextField,
    WxTimerDurationInput,
  },
  props: {
    panelHeight: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      maxJustifications: 5,
      newJustificationRowAccepted: true,
      justificationRows: [
        {
          index: 0,
          reasonId: null,
          reasonSelectorErrorMessages: [],
          isDurationMandatory: false,
          duration: "",
          maxDurationInSeconds: 0,
          durationLocked: false,
          durationErrorMessages: [],
          comment: "",
        },
      ],
      durationRegex: /^[0-9]{2}:[0-9]{2}:[0-9]{2}$/,

      workShiftCrossingDowntime: null,
      showConfirmJustificationDialog: false,
      disableSubmitJustification: false,
    };
  },
  computed: {
    ...mapGetters("dashboard", [
      "availableDowntimeReasons",
      "downtimes",
      "selectedDowntime",
      "currentDowntime",
      "currentDowntimeDuration",
      "unjustifiedDowntimes",
      "unjustifiedDowntimesBeforeSelected",
      "justificationCommentMaxLength",
      "activePUJustificationDelayInMillis",
      "workShiftCoverage",
    ]),
    ...mapGetters("navigation", ["activeFactory"]),
    reasonDurationInputLabel() {
      return this.$t("justification.panel.reasonDurationInputLabel");
    },
    downtimeSelectedIsFirst() {
      const justifiableDowntimes = this.downtimes.filter(
        (downtime) => downtime.end_time - downtime.start_time >= this.activePUJustificationDelayInMillis,
      );
      let currentlySelectedDowntime = justifiableDowntimes.findIndex(
        (downtime) => downtime.id === this.selectedDowntime?.id,
      );
      return currentlySelectedDowntime === 0;
    },
    downtimeSelectedIsLast() {
      const justifiableDowntimes = this.downtimes.filter(
        (downtime) => downtime.end_time - downtime.start_time >= this.activePUJustificationDelayInMillis,
      );
      let currentlySelectedDowntime = justifiableDowntimes.findIndex(
        (downtime) => downtime.id === this.selectedDowntime?.id,
      );
      return currentlySelectedDowntime === justifiableDowntimes.length - 1;
    },
    downtimeStart() {
      let millis = this.selectedDowntime?.start_time;
      if (this.activeFactory && this.activeFactory.timezone) {
        return TimeUtils.toTimeHHMMSS(millis, this.activeFactory.timezone);
      }
      return TimeUtils.toTimeHHMMSS(millis, this.activeFactory.timezone);
    },
    downtimeEnd() {
      if (this.activeFactory && this.activeFactory.timezone) {
        let millis = this.selectedDowntime?.end_time;
        if (millis) {
          return TimeUtils.toTimeHHMMSS(millis, this.activeFactory.timezone);
        } else {
          // No end time, it's an in-progress downtime
          return TimeUtils.toTimeHHMMSS(DateTime.utc().toMillis(), this.activeFactory.timezone);
        }
      }
      return "";
    },
    availableReasonsFlattenTree() {
      const selectorElements = [];
      if (this.showNoProductionJustification()) {
        selectorElements.push({
          name: this.$t("justification.noProduction"),
          targetDurationHHMM: "",
          nameWhenSelected: this.$t("justification.noProduction"),
          id: NO_PRODUCTION_REASON_ID,
          isOOP: true,
          htmlTagId: "pendo-early-finish-btn",
        });
      }
      let recommendedReasons = this.availableDowntimeReasons.filter((e) => e.recommended === true);
      if (recommendedReasons.length > 0) {
        selectorElements.push({ divider: true });
        selectorElements.push({ header: this.$t("justification.panel.recommendedReasonCategory") });
        recommendedReasons.forEach((element) => this.addReasonToSelector(selectorElements, element));
      }
      for (let element of this.availableDowntimeReasons) {
        if (element.type === "category") {
          selectorElements.push({ divider: true });
          selectorElements.push({ header: element.name });
        } else {
          this.addReasonToSelector(selectorElements, element);
        }
      }

      return selectorElements;
    },
    canDowntimeBeOutOfProduction() {
      if (this.selectedDowntimeIsCurrentDowntime) return true;
      const startTime = this.selectedDowntime?.start_time;
      const endTime = this.selectedDowntime?.end_time;

      // check to see if any work shift start events appear within the downtime start (exclusive) and downtime end (inclusive)
      const overlappingWorkShiftStarts = this.workShiftCoverage.filter((ws) => {
        return startTime < ws.start_time_millis && endTime >= ws.start_time_millis;
      });
      return overlappingWorkShiftStarts.length > 0;
    },
    selectedDowntimeIsCurrentDowntime() {
      // last downtime is not necessarily current downtime if not viewing the current window (day, shift, hour)
      // currentDowntime will be null if "isLiveData" is false since we can't be sure what is the current downtime
      // if we are not looking at current data (in the past).
      return this.currentDowntime?.id === this.selectedDowntime?.id;
    },
    selectedDowntimeDuration() {
      function isJustifiable(downtime, delayMillis) {
        if (delayMillis) {
          return downtime.end_time - downtime.start_time >= delayMillis;
        }
        return false;
      }
      if (
        this.currentDowntime &&
        isJustifiable(this.currentDowntime, this.activePUJustificationDelayInMillis) &&
        this.selectedDowntimeIsCurrentDowntime
      ) {
        return Duration.fromMillis(this.currentDowntimeDuration).toFormat(TIME_FORMAT_HH_MM_SS);
      } else {
        const duration = Duration.fromMillis(this.selectedDowntime.end_time - this.selectedDowntime.start_time, {});
        return duration.toFormat(TIME_FORMAT_HH_MM_SS);
      }
    },
    inlineStyleByBreakpoint() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return;
        case "sm":
          return;
        default:
          return `max-height:` + this.panelHeight;
      }
    },
    isDowntimeCrossingWorkShift() {
      return this.workShiftCrossingDowntime !== null;
    },
    isJustified() {
      if (this.justificationRows.length === 0) return false;
      return !this.justificationRows.every((j) => this.isJustificationRowEmpty(j));
    },
    isSplitByWorkShiftEnabled() {
      return this.isDowntimeCrossingWorkShift && !this.isJustified;
    },
    isJustificationButtonDisabled() {
      return (
        this.justificationRows.findIndex((jr) => jr.reasonId === NO_PRODUCTION_REASON_ID) >= 0 ||
        this.justificationRows.length >= this.maxJustifications
      );
    },
  },

  watch: {
    availableDowntimeReasons() {
      if (this.availableDowntimeReasons.length > 0) {
        this.justificationRows.forEach((j) => {
          j.durationLocked = isDurationLocked(j.reasonId, this.availableDowntimeReasons);
        });
      }
    },
    selectedDowntime(newValue, oldValue) {
      if (oldValue && (!newValue || JSON.stringify(newValue) === JSON.stringify(oldValue))) return;

      this.resetCustomValidation();
      this.$refs.justificationForm.resetValidation();
      this.fetchAvailableDowntimeReasons({ downtimeId: this.selectedDowntime.id });

      if (newValue && oldValue && newValue.id === oldValue.id) return;

      // Does it overlap a work shift?
      let workShiftStartDuringDowntime = null;
      if (this.workShiftCoverage) {
        for (let i = 0; i < this.workShiftCoverage.length; i++) {
          let ws = this.workShiftCoverage[i];
          if (
            ws.start_time_millis > this.selectedDowntime.start_time &&
            ws.start_time_millis < this.selectedDowntime.end_time
          ) {
            workShiftStartDuringDowntime = ws;
            break;
          }
        }
      }

      if (workShiftStartDuringDowntime) {
        this.workShiftCrossingDowntime = workShiftStartDuringDowntime;
      } else {
        this.workShiftCrossingDowntime = null;
      }

      this.justificationRows.length = 0;
      if (this.selectedDowntime.justifications) {
        this.selectedDowntime.justifications
          .filter((j) => this.aboveMinimumDurationMillis(j))
          .forEach((j, index) => {
            const durationLocked = j.reason ? isDurationLocked(j.reason.id, this.availableDowntimeReasons) : false;
            const reason = j.reason ? this.availableDowntimeReasons.find((r) => r.id === j.reason?.id) : null;
            this.justificationRows.push({
              index: index,
              reasonId: j.reason ? j.reason.id : null,
              reasonSelectorErrorMessages: null,
              isDurationMandatory: index < this.selectedDowntime.justifications.length - 1,
              duration: formatDurationForInput(j.start_time, j.end_time),
              durationLocked: durationLocked,
              durationErrorMessages: [],
              comment: j.comment,
              maxDurationInSeconds: reason?.target_duration_in_seconds ?? 0,
            });
          });
      } else {
        this.justificationRows.push({
          index: this.justificationRows.length + 1,
          reasonId: null,
          reasonSelectorErrorMessages: [],
          isDurationMandatory: false,
          duration: "",
          durationLocked: false,
          durationErrorMessages: [],
          comment: "",
          maxDurationInSeconds: 0,
        });
      }
    },
  },

  methods: {
    aboveMinimumDurationMillis(justification) {
      if (justification.end_time) {
        return justification.end_time - justification.start_time >= 1000;
      }
      return true;
    },
    showNoProductionJustification() {
      if (!this.selectedDowntime) return false;
      const currentJustificationsMillis = this.justificationRows.reduce(
        (acc, currentValue) => acc + this.reasonDurationToMillis(currentValue.duration),
        0,
      );
      const downtimeStartMillis = this.selectedDowntime.start_time;
      const lastJustificationMillis = downtimeStartMillis + currentJustificationsMillis;
      const workShiftStartOverlappingJustifications = this.workShiftCoverage.filter(
        (ws) => ws.start_time_millis >= downtimeStartMillis && ws.start_time_millis <= lastJustificationMillis,
      );
      return (
        (this.selectedDowntimeIsCurrentDowntime || this.canDowntimeBeOutOfProduction) &&
        workShiftStartOverlappingJustifications <= 0
      );
    },
    isJustificationRowEmpty(justificationRow) {
      return justificationRow.reasonId === null && justificationRow.duration === "" && justificationRow.comment === "";
    },
    getReasonName(reasonId) {
      if (!this.availableReasonsFlattenTree || this.availableReasonsFlattenTree.length === 0) return "";
      const name = this.availableReasonsFlattenTree.find((r) => r.id === reasonId)?.name;
      return name ? name : "";
    },
    ...mapActions("dashboard", [
      "fetchAvailableDowntimeReasons",
      "unselectDowntime",
      "selectPreviousDowntime",
      "selectNextDowntime",
      "selectPreviousDowntimeUnjustified",
      "selectLastDowntimeUnjustified",
      "fetchTimelineElements",
    ]),
    ...mapActions("operation", ["showOperationSuccess", "showOperationError"]),
    addReasonToSelector(selector, element) {
      let targetDurationHHMM = "";
      if (element.target_duration_in_seconds) {
        targetDurationHHMM = DurationUtils.secondsToHHMM(element.target_duration_in_seconds);
      }
      selector.push({
        name: element.name,
        targetDurationHHMM: targetDurationHHMM,
        nameWhenSelected: element.name,
        id: element.id,
        planned: element.planned,
      });
    },
    getPlannedUnplannedIcon(reason) {
      if (reason.isOOP) return "mdi-stop-circle-outline";
      return reason.planned ? "$plannedReasonIcon" : "$unplannedReasonIcon";
    },
    isDeletionAllowed(index) {
      if (this.justificationRows.length <= 1) {
        return false;
      }
      // There are at least 2 rows. Are we processing the last row (the one with no duration) ?
      if (index === this.justificationRows.length - 1) {
        // The function is deciding if the current row - the last one - can be deleted
        // If there is at least one locked duration, this last row cannot be removed.
        const rowWithLockedDuration = this.justificationRows.find((j) => j.durationLocked);
        return !rowWithLockedDuration;
      }
      return this.justificationRows.length > 1;
    },

    isDurationDisabled(justificationRow) {
      return justificationRow.durationLocked;
    },

    clearAndSplitByWorkShift() {
      this.justificationRows = [];

      const beforeWorkShiftMarkDurationMillis =
        this.workShiftCrossingDowntime.start_time_millis - this.selectedDowntime.start_time;

      this.justificationRows.push({
        index: 0,
        reasonId: null,
        reasonSelectorErrorMessages: [],
        isDurationMandatory: true,
        duration: DurationUtils.millisToHHMMSS(beforeWorkShiftMarkDurationMillis),
        durationLocked: false,
        durationErrorMessages: [],
        comment: "",
        maxDurationInSeconds: beforeWorkShiftMarkDurationMillis / 1000,
      });
      this.justificationRows.push({
        index: 1,
        reasonId: null,
        reasonSelectorErrorMessages: [],
        isDurationMandatory: false,
        duration: "",
        durationLocked: false,
        durationErrorMessages: [],
        comment: "",
        maxDurationInSeconds: 0,
      });
    },

    addJustificationRow() {
      this.justificationRows.forEach((r) => (r.isDurationMandatory = true));
      this.justificationRows.push({
        index: this.justificationRows.length + 1,
        reasonId: null,
        reasonSelectorErrorMessages: [],
        isDurationMandatory: false,
        duration: "",
        durationLocked: false,
        durationErrorMessages: [],
        comment: "",
        maxDurationInSeconds: 0,
      });
    },

    removeJustification(index) {
      // `splice(n, 1)` removes 1 element (second parameter) at index `n` (first parameter)
      this.justificationRows.splice(index, 1);

      let lastJustificationRow = this.justificationRows[this.justificationRows.length - 1];
      lastJustificationRow.isDurationMandatory = false;
      lastJustificationRow.duration = "";

      this.resetCustomValidation();
      this.validateTotalDuration();
    },

    validateDuration(justificationRow) {
      if (justificationRow && justificationRow.isDurationMandatory) {
        let duration = justificationRow.duration;
        if (!duration) {
          justificationRow.durationErrorMessages = [this.$t("justification.errors.noDuration")];
          return this.$t("justification.errors.noDuration");
        }
        let invalidDurationMessage = this.$t("justification.errors.invalidDuration");
        if (duration.length === 0) {
          justificationRow.durationErrorMessages = [invalidDurationMessage];
          return invalidDurationMessage;
        }
        if (!this.durationRegex.test(String(duration).toLowerCase())) {
          justificationRow.durationErrorMessages = [invalidDurationMessage];
          return invalidDurationMessage;
        }
        const parts = duration.split(":");
        const minute = Number(parts[1]);
        if (minute < 0 || minute > 59) {
          justificationRow.durationErrorMessages = [this.$t("justification.errors.durationMinuteAboveLimit")];
          return this.$t("justification.errors.durationMinuteAboveLimit");
        }
        const second = Number(parts[2]);
        if (second < 0 || second > 59) {
          justificationRow.durationErrorMessages = [this.$t("justification.errors.durationSecondAboveLimit")];
          return this.$t("justification.errors.durationSecondAboveLimit");
        }
        const durationMillis = this.reasonDurationToMillis(duration);
        if (durationMillis < 1000) {
          justificationRow.durationErrorMessages = [this.$t("justification.errors.singleDurationUnderLimit")];
          return this.$t("justification.errors.singleDurationUnderLimit");
        }
        // Check if that duration is greater than the downtime duration!
        if (
          this.selectedDowntime &&
          durationMillis > this.selectedDowntime.end_time - this.selectedDowntime.start_time
        ) {
          // The duration configured is larger than the Downtime duration, error!
          justificationRow.durationErrorMessages = [this.$t("justification.errors.singleDurationSumExceeded")];
          return this.$t("justification.errors.singleDurationSumExceeded");
        }
      }
      return true;
    },

    validateComment(index) {
      let r = this.justificationRows[index];
      if (r && r.comment) {
        if (r.comment.trim().length > this.justificationCommentMaxLength) {
          return this.$t("justification.errors.commentTooLong", { maxLength: this.justificationCommentMaxLength });
        }
      }
      return true;
    },

    getDowntimeReason(downtimeReasonId) {
      return this.availableDowntimeReasons.find((r) => r.id === downtimeReasonId);
    },

    onSelectedReason(justificationRow, selectedReasonId, index) {
      this.resetCustomValidation();
      this.$refs.justificationForm.resetValidation();
      this.newJustificationRowAccepted = true;

      const reason = this.getDowntimeReason(selectedReasonId);

      if (selectedReasonId === NO_PRODUCTION_REASON_ID) {
        // the 'No Production' option was selected, for now everything after needs to be removed and the duration must be set to null
        // remove any justifications after the 'No Production' justification
        this.justificationRows.splice(index + 1);
        // make sure the 'No Production' justification goes to the end of the downtime
        justificationRow.duration = "";
        justificationRow.comment = "";
        justificationRow.durationLocked = false;
        justificationRow.isDurationMandatory = false;
        justificationRow.durationErrorMessages = [];
        justificationRow.reasonSelectorErrorMessages = [];
        justificationRow.maxDurationInSeconds = 0;
        // set the 'No Production' justification
        this.justificationRows[index] = justificationRow;
        return;
      }

      if (reason && reason.target_duration_in_seconds) {
        justificationRow.maxDurationInSeconds = reason.target_duration_in_seconds;
        justificationRow.duration = DurationUtils.secondsToHHMMSS(reason.target_duration_in_seconds);
        justificationRow.durationLocked = true;

        // Prevent new row if total time exceeds downtime
        if (this.justificationsTotalDuration() > this.selectedDowntime.end_time - this.selectedDowntime.start_time) {
          this.newJustificationRowAccepted = false;
        }

        // Remove duration for last item
        if (index === this.justificationRows.length - 1) {
          justificationRow.duration = "";
          justificationRow.durationLocked = false;
        }

        if (
          this.newJustificationRowAccepted &&
          this.justificationRows.length < this.maxJustifications &&
          this.isLastJustificationRowJustified()
        ) {
          this.addJustificationRow();
        }
      } else {
        // Here. The reason has no target duration -> remove any duration constraints
        justificationRow.durationLocked = false;
        justificationRow.maxDurationInSeconds = 0;
      }

      this.validateTotalDuration();
    },

    onNewDuration(justificationRow, newDuration) {
      this.resetCustomValidation();
      this.$refs.justificationForm.resetValidation();

      const newDurationInSeconds = DurationUtils.hhmmToSeconds(newDuration);
      if (newDurationInSeconds > justificationRow.maxDurationInSeconds && justificationRow.maxDurationInSeconds > 0) {
        const targetDuration = DurationUtils.secondsToHHMM(justificationRow.maxDurationInSeconds);
        justificationRow.durationErrorMessages = [
          this.$t("justification.errors.exceedsTargetDuration", { targetDuration: targetDuration }),
        ];
        return;
      }
      this.$refs.justificationForm.validate();
      this.validateTotalDuration();
    },

    isLastJustificationRowJustified() {
      const lastJustificationRow = this.justificationRows[this.justificationRows.length - 1];
      return lastJustificationRow && lastJustificationRow.reasonId;
    },

    createJustification(justificationRow) {
      return {
        downtime_reason_id: justificationRow.reasonId,
        duration_millis: this.reasonDurationToMillis(justificationRow.duration),
        comment: this.getComment(justificationRow),
      };
    },

    getComment(justificationRow) {
      if (justificationRow.comment) {
        return justificationRow.comment;
      }
      return null;
    },

    reasonDurationToMillis(durationAsString) {
      if (durationAsString === "") return null;
      const parts = durationAsString.split(":");
      const hours = Number(parts[0]);
      const minutes = Number(parts[1]);
      const seconds = Number(parts[2]);
      return hours * 3600000 + minutes * 60000 + seconds * 1000;
    },

    invalidateDurationInput(r) {
      r.durationErrorMessages = [this.$t("justification.errors.durationSumExceeded")];
    },

    invalidateReasonSelectionInput(r) {
      r.reasonSelectorErrorMessages = [this.$t("justification.errors.noReason")];
    },

    justificationsTotalDuration() {
      const configuredDurationAccumulationFunction = (accumulator, currentDurationMillis) =>
        accumulator + currentDurationMillis;

      return this.justificationRows
        .filter((r) => r.duration)
        .map((r) => this.reasonDurationToMillis(r.duration))
        .reduce(configuredDurationAccumulationFunction, 0);
    },

    validateTotalDuration() {
      const totalDurationMillis = this.justificationsTotalDuration();
      if (totalDurationMillis > this.selectedDowntime.end_time - this.selectedDowntime.start_time) {
        // The duration configured is larger than the Downtime duration, error!
        this.justificationRows.filter((r) => r.duration).forEach((r) => this.invalidateDurationInput(r));
        return false;
      }
      return true;
    },

    validateLastJustificationTargetDurationExceeded() {
      let lastJustification = this.justificationRows[this.justificationRows.length - 1];
      if (lastJustification.maxDurationInSeconds === 0) return true;

      // if reason selection is done while duration hasn't reach target,
      // BUT submit is done after target duration is reach, we need to revalidate & block user
      let totalJustificationsDuration =
        this.justificationsTotalDuration() + lastJustification.maxDurationInSeconds * 1000;
      let totalDowntimeDuration = this.selectedDowntime.end_time - this.selectedDowntime.start_time;
      if (totalJustificationsDuration < totalDowntimeDuration) {
        lastJustification.duration = DurationUtils.secondsToHHMMSS(lastJustification.maxDurationInSeconds);
        this.addJustificationRow();
        this.$refs.justificationForm.validate();
        return false;
      } else return true;
    },

    validateAtLeastOneReasonSelected() {
      let justificationWithReasonIdDefined = this.justificationRows.filter((j) => j.reasonId !== null);
      if (justificationWithReasonIdDefined.length === 0) {
        this.justificationRows.forEach((r) => this.invalidateReasonSelectionInput(r));
        return false;
      } else {
        return true;
      }
    },

    onSubmitBtnClick() {
      if (this.justificationRows.find((jr) => jr.reasonId === NO_PRODUCTION_REASON_ID)) {
        this.showConfirmJustificationDialog = true;
      } else {
        this.submit();
      }
    },

    onCancelSubmitBtnClick() {
      this.showConfirmJustificationDialog = false;
    },

    async submit() {
      // Disable submit button until API call is done to avoid race conditions in creation.
      this.disableSubmitJustification = true;

      this.showConfirmJustificationDialog = false;
      this.resetCustomValidation();
      let isFormValid = this.$refs.justificationForm.validate();
      const atLeastOneReasonFound = this.validateAtLeastOneReasonSelected();
      const durationSumValid = this.validateTotalDuration();
      const lastJustificationValid = this.validateLastJustificationTargetDurationExceeded();
      if (isFormValid && atLeastOneReasonFound && durationSumValid && lastJustificationValid) {
        await this.submitJustifications();
      }

      // API call is done, can submit justifications again.
      this.disableSubmitJustification = false;
    },

    remainingUnselectedUnjustifiedDowntimesCount() {
      return this.unjustifiedDowntimes.filter((downtime) => downtime.id !== this.selectedDowntime.id).length;
    },

    async submitJustifications() {
      const justifications = this.justificationRows.map((row) => this.createJustification(row));
      if (justifications.length === 1) {
        // Make sure there is no duration_minutes when only 1 reason has been selected
        justifications[0].duration_minutes = null;
      }
      await DowntimeJustificationService.setDowntimeJustifications(this.selectedDowntime.id, justifications)
        .then(() => this.handleRejectSuccess())
        .catch((error) => this.handleRejectError(error.response));
    },

    handleRejectSuccess() {
      this.showOperationSuccess(this.$t("justification.successfullyUpdated"));
      this.fetchTimelineElements();
      this.resetCustomValidation();
      this.$refs.justificationForm.resetValidation();

      if (this.remainingUnselectedUnjustifiedDowntimesCount() === 0) this.unselectDowntime();
      else if (this.unjustifiedDowntimesBeforeSelected.length > 0) this.selectPreviousDowntimeUnjustified();
      else this.selectLastDowntimeUnjustified();
    },

    handleRejectError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
    },

    getErrorMessage(code) {
      switch (code) {
        case "DSH_DJUST_PUT_20001":
          return this.$t("justification.errors.downtimeNotFound");
        case "DSH_DJUST_PUT_20002":
          return this.$t("justification.errors.durationSumExceeded");
        case "DSH_DJUST_PUT_20003":
          return this.$t("justification.errors.unknownReason");
        case "DSH_DJUST_PUT_20004":
          this.validateLastJustificationTargetDurationExceeded();
          return this.$t("justification.errors.submitExceedsTargetDuration");
        default:
          return this.$t("common.errors.default", { code: code });
      }
    },

    resetCustomValidation() {
      this.justificationRows.forEach((r) => {
        r.reasonSelectorErrorMessages = [];
        r.durationErrorMessages = [];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-downtime-justification {
  padding: var(--box-padding-dashboard);
  background-color: var(--color-base-background);
  box-shadow: none !important;

  .v-form {
    height: 100%; // required for `.scrollbar-y-container`
  }

  @media ($wx-sm-min) {
    .panel-header,
    .panel-body {
      display: flex;
    }
  }
}

.panel-header {
  align-items: center;
  margin-bottom: 5px;

  > :first-child {
    margin-right: auto;
  }
  .downtime-specs {
    font-weight: normal;
  }
  .arrows {
    margin-left: var(--btn-inline-margin);
  }

  // mobilePhone only
  @media ($wx-xs-max) {
    .v-btn,
    .arrow {
      margin-top: 0.5rem;
    }
    .arrows {
      display: inline-block;
      padding-top: 5px;
    }
  }
  @media ($wx-sm-min) {
    padding-right: 50px; // space for close-btn on the right
  }
}

// Fieldset
.justification-row {
  border: none;

  .v-btn {
    margin-top: 0.3rem;

    &.btn-delete {
      min-width: 30px;
      max-width: 30px;
      padding: 0;
    }
  }

  // mobilePhone only
  @media ($wx-xs-max) {
    margin-top: 1rem;

    ::v-deep .select-reason,
    .input-justification,
    .input-comment {
      max-width: 98%;
    }

    .v-btn {
      &.btn-delete {
        margin-top: -0.5rem;
        margin-bottom: 1rem;
      }
    }
  }
  // mobile & desktop
  @media ($wx-sm-min) {
    display: flex;
    gap: 10px;
    align-content: center;
    margin-top: 0.25rem;

    .input-justification {
      flex-grow: 1;
      min-width: 120px;
      max-width: 150px;
    }
    .input-comment {
      flex-grow: 1;
    }
    ::v-deep .select-reason {
      max-width: 320px;
    }
  }
}

//  Justification Reason Dialog
.select-reason--items {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .state-icon {
    opacity: 0.5;
  }

  .inline-target-value {
    color: var(--color-text-subtle-theme);
    font-weight: 300;
    white-space: nowrap;

    .v-icon {
      margin-top: -0.2rem;
      padding-right: 0.2rem;
      opacity: 0.6;
    }
  }
}
</style>
