<template>
  <v-card
    :wxid="$options.name"
    :class="[{ disabled: isTileDisabled, unfocused: isTileUnfocused }]"
    class="tile product-giveaway"
    tag="section"
    sf="4"
  >
    <grouped-tile-selector-menu :tile-index="tileIndex" />
    <div class="tile-inner">
      <h3 :title="$t('tiles.productGiveaway')" class="primary-title">
        {{ $t("tiles.productGiveaway") }}
        <v-icon v-if="!isTileAuthorized()">mdi-lock</v-icon>
      </h3>
      <h2 class="primary-value">{{ primaryValue }}</h2>
      <p class="value-sub-text__unit">({{ $t("tiles.currentProductNote") }})</p>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import GroupedTileSelectorMenu from "@/components/dashboard/tiles/GroupedTileSelectorMenu";
import TileBase from "@/components/dashboard/tiles/TileBase";
import Tiles from "@/components/Tiles";
import Helpers, { dash } from "@/helpers";

export default {
  name: "TileProductGiveaway",
  extends: TileBase,
  inheritAttrs: false,
  components: { GroupedTileSelectorMenu },
  computed: {
    ...mapGetters("dashboard", ["productGiveaway"]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    ...mapGetters("tiles", ["selectedTileIndex"]),
    isTileUnfocused() {
      return this.selectedTileIndex !== null && this.tileIndex !== this.selectedTileIndex;
    },
    isTileDisabled() {
      return !this.isTileAuthorized();
    },
    unitName() {
      if (this.productGiveaway.unit && this.isTileAuthorized()) {
        return this.productGiveaway.unit;
      } else {
        return "";
      }
    },
    // Product Giveaway (units)
    primaryValue() {
      let roundedValueOrDash = Helpers.getRoundedValueOrZeroOrDash(
        this.productGiveaway?.value,
        this.isTileAuthorized(),
      );
      return roundedValueOrDash !== dash ? `${roundedValueOrDash} ${this.unitName}` : roundedValueOrDash;
    },
    targetQuantityUnits() {
      let roundedValueOrDash = Helpers.getRoundedValueOrDash(this.productGiveaway?.target, this.isTileAuthorized());
      return roundedValueOrDash !== dash ? `${roundedValueOrDash} ${this.unitName}` : roundedValueOrDash;
    },
  },
  methods: {
    isTileAuthorized() {
      return this.activePuHasRequiredFeature(Tiles.productGiveaway.requiredFeature);
    },
  },
};
</script>
